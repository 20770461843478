import axios from 'axios';


export const transport = axios.create({
    baseURL: "https://api.trypadlock.com/",
    // baseURL: "http://localhost:8000/",
    headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
        "Content-Type": "application/json",
        accept: "application/json",
    },
});

transport.interceptors.response.use(
    (response) => {
        // console.log('RESPONSEauth', response)
        return response;
    },
    (error) => {
        const originalRequest = error.config;
        console.log("ERROR", error);
        var reCheck = false;

        if (error.response == undefined) reCheck = true;
        if (reCheck == false && error.response.status === 401) reCheck = true;

        if (reCheck == true) {
            const refresh_token = localStorage.getItem("refresh_token");

            return fetch("https://api.trypadlock.com/refresh/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    refresh: refresh_token,
                }),
            })
                .then((response) => {
                    console.log("RESPONSErefreshTOKEN", response);
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then((res, data) => {
                    console.log("res", res);
                    console.log("data", data);
                    if (res[0] === 401) {
                        console.log("401 ERROR");
                    } else {
                        localStorage.setItem("token", res[1].access);
                        localStorage.setItem("refresh_token", res[1].refresh);

                        transport.defaults.headers["Authorization"] = "Jwt " + res[1].access;
                        originalRequest.headers["Authorization"] = "Jwt " + res[1].access;

                        return transport(originalRequest);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return Promise.reject(error);
    }
);


