import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GTable from "../GTable";
import NPagination from "../Pagination/NPagination";
import ProfileNav from "../Profile/ProfileNav";
import Modal from "react-responsive-modal";
import {
  ChoosePicture,
  EnterVaildEmail,
  User_Notadded_to_Cv,
  ErrorCreatingUser,
  ErrorUploadingPicture,
  FillAllFields,
  User_added_to_Cv,
  OrgUpdatedSuccessfully,
  PlanSavedSuccess,
  UpdatedOrgPic,
  UserAlreadyExists,
  UserCreatedSuccessfully,
  ServerError,
  Org_added_to_Cv,
  Edit_Org_cv,
  Delete_Org_cv,
  TrialExendSuccessfully,
  Created_app_Feature,
} from "../NotificationToast";
import { toast, ToastContainer } from "react-toastify";
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import Dropdown from "react-dropdown";
import {
  getOrgs,
  getOwner,
  getRevenueOfORG,
  getUserListofORG,
} from "../../services/loggedInApp";
import axios from "axios";
import { getPlans } from "../../services/loggedInApp";
import TimezoneSelect from "react-timezone-select";
import "react-dropdown/style.css";
import "./DropDown.css";
import "../../styles/modal.css";
import { PlanCard } from "./SetPlanModal";
import { Scrollbars } from "react-custom-scrollbars";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import OrgTeams from "./OrgTeams";
import moment from "moment";

import ReactHighcharts from "react-highcharts";
import { refreshTokenn } from "../../services/auth";
import SetPlanModal from "../setPlanModal/SetPlanModal";
import { transport } from "../../services/api";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { deleteORG } from "../../services/loggedInApp";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EachOrgPage = () => {
  const validEmailRegex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const history = useHistory();
  const location = useLocation();
  const locState = location.state;
  const fileInputRef = useRef(null);

  // console.log('locstate',locState)

  const [orgsUserList, setOrgsUserList] = useState([]);
  const [pageOrgUserNumber, setPageOrgUserNumber] = useState(0);
  const [editOrgName, setEditOrgName] = useState("");
  const [fixedValueOrg, setfixedValueOrg] = useState("");
  const [uData, setUData] = useState({});
  const [orgPic, setOrgPic] = useState([]);

  const [isOpenModel, setIsOpenModal] = useState(false);
  const [editUserModel, setEditUserModel] = useState(false);
  const [isPlanModal, setIsPlanModal] = useState(false);
  const [priceData, setPriceData] = useState([]);

  const [addUserNameOrg, setAddUserNameOrg] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [orgsList, setOrgsList] = useState("");
  const [trailDays, setTrailDays] = useState({});

  const [orgCVs, setOrgCVs] = useState([]);
  const [orgFeatures, setOrgFeatures] = useState([]);
  const [curr, setCurr] = useState("");
  const [currency, setCurrency] = useState("inr");
  const [features, setFeatures] = useState([]);
  const [selFeatures, setSelFeatures] = useState([]);
  const [editPrice, setEditPrice] = useState(null);
  const [newPrice, setNewPrice] = useState(0);
  // const [orgCvkey,setOrgCvKey] = useState();
  const [ownerDetails, setOwnerDetails] = useState({});
  const [createorgCvModal, setCreateOrgCvModal] = useState(false);
  const [showAssignFeatureModal, setShowAssignFeatureModal] = useState(false);
  const [editOrgCvModal, setEditOrgCvModal] = useState(false);
  const [deleteOrgCvModal, setDeleteOrgCvModal] = useState(false);
  const [placeholderValue_orgCV_edit, setPlaceholderValue_orgCV_edit] =
    useState();
  const [deleteOrgCV_key, setDeleteOrgCV_key] = useState();
  const [orgValueName, setOrgValueName] = useState();
  const [orgKeyName, setOrgKeyName] = useState();

  const [timezone, setTimeZone] = useState({});
  // Intl.DateTimeFormat().resolvedOptions().timeZone
  const rolesOptions = ["Manager", "Member"];

  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isNameOpen, setIsNameOpen] = useState(false);

  const [showTrailPeriodModal, setShowTrialPeriodModal] = useState(false);
  const [trialEndDate, setTrialEndDate] = useState("");

  const [userLoginChartData, setUserLoginChartData] = useState([]);

  const [deactivatedUsers, setDeactivatedUsers] = useState([]);
  const [deactivatedUserPageNumber, setDeactivatedUserPageNumber] = useState(0);

  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [inactiveUsersPageNumber, setInactiveUsersPageNumber] = useState(0);

  const [webLoggedUsers, setWebLoggedUsers] = useState([]);
  const [webLoggedPageNumber, setWebLoggedPageNumber] = useState(0);

  const [mobileLoggedUsers, setMobileLoggedUsers] = useState([]);
  const [mobileLoggedPageNumber, setMobileLoggedPageNumber] = useState(0);

  const [desktopLoggedUsers, setDesktopLoggedUsers] = useState([]);
  const [desktopLoggesPageNumber, setDesktopLoggedPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const handleRoleSelect = (role) => {
    setRole(role.value);
  };

  const onCloseModel = () => {
    setIsOpenModal(false);
    setEditUserModel(false);
    setIsPlanModal(false);
    setEditOrgCvModal(false);
    setCreateOrgCvModal(false);
    setDeleteOrgCvModal(false);
    setShowAssignFeatureModal(false);
  };
  // const onCloseModel = () => {
  //   setIsOpenModal(false);
  //   setEditUserModel(false);
  //   setIsPlanModal(false);
  //   setEditOrgCvModal(false);
  //   setCreateOrgCvModal(false);
  //   setDeleteOrgCvModal(false);
  // }
  const onOpenModal = () => setIsPlanModal(true);

  useEffect(() => {
    getOrgRevenue();
    getUserlistOrg();
    fetchPlans();
    getAllOrgs();
    getOrgCv();
    fetchTrailDays();
    userLoginChart();
    getDeactiviatedUsers(locState.uuidOrg);
    getInactiveUsers(locState.uuidOrg);
    getWebLoggedUsers(locState.uuidOrg);
    getMobileLoggedusers(locState.uuidOrg);
    getDesktopLoggedUsers(locState.uuidOrg);
    getOwnerData();
    transport("users/list_features").then((res) => {
      setFeatures(res?.data?.features);
    });
  }, []);

  const stats = [
    {
      id: 1,
      name: "This month total revenue",
      stat:
        uData.thismonthRevune == undefined ? (
          <p className='loader'></p>
        ) : (
          uData.thismonthRevune
        ),
      change: "122",
      changeType: "increase",
    },
    {
      id: 2,
      name: "Today active users",
      stat:
        uData.activeToday == undefined ? (
          <p className='loader'></p>
        ) : (
          uData.activeToday
        ),
      change: "5.4%",
      changeType: "increase",
    },
    {
      id: 3,
      name: "Today new signups",
      stat:
        uData.createdToday == undefined ? (
          <p className='loader'></p>
        ) : (
          uData.createdToday
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 4,
      name: "Last month total revenue",
      stat:
        uData.lastmonthRevune == undefined ? (
          <p className='loader'></p>
        ) : (
          uData.lastmonthRevune
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 5,
      name: "Yesterday active users",
      stat:
        uData.activeYesterday == undefined ? (
          <p className='loader'></p>
        ) : (
          uData.activeYesterday
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 6,
      name: "Yesterday new signups",
      stat:
        uData.createdYesterday == undefined ? (
          <p className='loader'></p>
        ) : (
          uData.createdYesterday
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 7,
      name: "Weekly active users",
      stat:
        uData.activeWeekly == undefined ? (
          <p className='loader'></p>
        ) : (
          uData.activeWeekly
        ),
      change: "3.2%",
      changeType: "decrease",
    },
  ];

  const extendTrialPeriod = () => {
    fetch("https://api.trypadlock.com/graph/extend_trail", {
      method: "POST",
      headers: {
        Authorization: "Jwt " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        end_date: trialEndDate,
        org_uuid: locState.uuidOrg,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        TrialExendSuccessfully();
      });
  };
  const getOwnerData = () => {
    getOwner(locState.uuidOrg).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setOwnerDetails(res.data);
      }
    });
  };
  const fetchTrailDays = async () => {
    const response = await fetch(
      `https://api.trypadlock.com/users/number_days?org_uuid=${locState.uuidOrg}`,
      {
        method: "GET",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status == 500) {
          setTrailDays(null);
          return;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        // console.log('res ==',res)
        setTrailDays(res);
      })
      .catch((err) => console.log("err ==", err));
  };

  const fetchPlans = () => {
    getPlans(locState.appId).then((res) => {
      if (res.error) {
        console.log("err in fetchplans", res.error);
      } else {
        setPriceData(res.data.data);
      }
    });
  };

  const getUserlistOrg = () => {
    getUserListofORG(locState.appId, locState.orgId).then((res) => {
      if (res.data.data == "") {
        setOrgsUserList(undefined);
      } else {
        let orguserslist = res.data.data;
        if (orguserslist && orguserslist.length > 0) {
          orguserslist
            .sort((a, b) => new Date(a.last_login) - new Date(b.last_login))
            .reverse();
        }
        setOrgsUserList(orguserslist);
      }
    });
  };

  const getOrgRevenue = () => {
    getRevenueOfORG(locState.uuidOrg).then((res) => {
      setUData(res.data);
    });
  };

  const getAllOrgs = () => {
    getOrgs(locState.appId).then((res) => {
      if (res.data.data == undefined || res.data.data == "") {
        setOrgsList(undefined);
      } else {
        setOrgsList(res.data.data);
      }
    });
  };

  //sorting the appOrg using Name
  const sortNameAscending = () => {
    orgsUserList.sort((a, b) => a.username.localeCompare(b.username)).reverse();
  };

  const sortNameDescending = () => {
    orgsUserList.sort((a, b) => a.username.localeCompare(b.username));
  };

  const nameArrowSort = () => {
    if (isNameOpen === false) {
      sortNameDescending();
    } else {
      sortNameAscending();
    }
  };

  const nameSortOpen = () => {
    if (isNameOpen === false) {
      setIsNameOpen(true);
    } else {
      setIsNameOpen(false);
    }
  };

  //sorting the appOrg using date
  const sortDateAscending = () => {
    orgsUserList
      .sort((a, b) => new Date(a.created) - new Date(b.created))
      .reverse();
  };

  const sortDateDescending = () => {
    orgsUserList.sort((a, b) => new Date(a.created) - new Date(b.created));
  };

  const dateArrowSort = () => {
    if (isDateOpen === false) {
      sortDateDescending();
    } else {
      sortDateAscending();
    }
  };

  const dateSortOpen = () => {
    if (isDateOpen === false) {
      setIsDateOpen(true);
    } else {
      setIsDateOpen(false);
    }
  };

  //sorting the appOrg using lastlogged in date
  const [isLastLogged, setIsLastLogged] = useState(false);

  const sortLoggedDateAscending = () => {
    orgsUserList
      .sort((a, b) => new Date(a.last_login) - new Date(b.last_login))
      .reverse();
  };

  const sortLoggedDateDescending = () => {
    orgsUserList.sort(
      (a, b) => new Date(a.last_login) - new Date(b.last_login)
    );
  };

  const loggedArrowSort = () => {
    if (isLastLogged === false) {
      sortLoggedDateAscending();
    } else {
      sortLoggedDateDescending();
    }
  };

  const loggedSortOpen = () => {
    if (isLastLogged === false) {
      setIsLastLogged(true);
    } else {
      setIsLastLogged(false);
    }
  };

  const orgpicture =
    orgsList == "" ? "" : orgsList.find((val) => val.id == locState.orgId);

  const uploadPicOrganization = () => {
    if (orgPic == "" || orgPic == []) {
      ChoosePicture();
    } else {
      const headers = {
        authorization: "Jwt " + localStorage.getItem("token"),
      };
      const fd = new FormData();
      fd.append("org_avatar", orgPic, orgPic.name);
      fd.append("org_uuid", locState.uuidOrg);
      axios
        .post("https://api.trypadlock.com/org/orgIcon", fd, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status == "picture has been uploaded") {
            setIsOpenModal(false);
            UpdatedOrgPic();
            setOrgPic("");
            getAllOrgs();
          } else {
            ErrorUploadingPicture();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const addUserOrg = () => {
    let emailtest = validEmailRegex.test(email);
    if (
      addUserNameOrg == "" ||
      firstName == "" ||
      lastName == "" ||
      password == ""
    ) {
      FillAllFields();
    } else {
      if (emailtest) {
        fetch("https://api.trypadlock.com/users/OrgUser", {
          method: "POST",
          headers: {
            authorization: "Jwt " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            username: addUserNameOrg,
            first_name: firstName,
            last_name: lastName,
            password: password,
            appid: locState.appId,
            org_uuid: locState.uuidOrg,
            roles: role,
            time_zone: timezone.value,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "user got created") {
              getUserlistOrg();
              UserCreatedSuccessfully();
              setIsOpenModal(false);
              editUserModel(false);
            } else if (data.status == "user already exists") {
              UserAlreadyExists();
            } else {
              ErrorCreatingUser();
            }
          });
      } else {
        EnterVaildEmail();
      }
    }
  };

  const usersperPage = 8;
  const pagesOrgUserVisited = pageOrgUserNumber * usersperPage;
  const pageUserOrgCount = Math.ceil(
    orgsUserList == undefined ? "" : orgsUserList.length / usersperPage
  );

  const changeOrgUserPage = ({ selected }) => {
    setPageOrgUserNumber(selected);
  };

  const handleNavigation = (e, username, userId, type) => {
    e.preventDefault();
    switch (type) {
      case "userPage":
        history.push({
          pathname: `/${locState.appName}/${locState.orgName}/${username}`,
          state: {
            username: username,
            orgId: locState.orgId,
            orgUuid: locState.uuidOrg,
            appName: locState.appName,
            user_id: userId,
            appId: locState.appId,
            orgName: locState.orgName,
          },
        });
        break;
      case "orgLog":
        history.push({
          pathname: `/${locState.appName}/${locState.orgName}/activitylog`,
          state: {
            orgId: locState.orgId,
            orgUuid: locState.uuidOrg,
            appName: locState.appName,
            appId: locState.appId,
          },
        });
        break;
      case "payments":
        history.push({
          pathname: `/${locState.appName}/${locState.orgName}/payments`,
          state: {
            orgId: locState.orgId,
            orgUuid: locState.uuidOrg,
            appName: locState.appName,
            appId: locState.appId,
          },
        });
        break;
      default:
        console.log("Error Navigating");
    }
  };

  const addOrgCv = (e) => {
    e.preventDefault();
    fetch(`https://api.trypadlock.com/org/org_data`, {
      method: "POST",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        org_uuid: locState.uuidOrg,
        name: orgKeyName,
        main_key: orgValueName,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        Org_added_to_Cv();
        setOrgKeyName("");
        setOrgValueName("");
        onCloseModel();
        getOrgCv();
      })
      .catch((err) => console.log("error in add orgcv", err));
  };
  const addOrgFeatures = async (e) => {
    e.preventDefault();
    const promises = [];
    if (!selFeatures || selFeatures.length === 0) {
      FillAllFields();
    } else {
      for (let i = 0; i < selFeatures.length; i++) {
        let feature = {
          id: selFeatures[i]["id"],
          price: selFeatures[i]["feature_price"],
          name: selFeatures[i]["name"],
          description: selFeatures[i]["description"],
        };
        const res = await transport("users/assign_feature", {
          method: "PUT",
          data: {
            org_uuid: locState.uuidOrg,
            currency: currency,
            feature: feature,
          },
        });
        if (res.status == 200) {
          promises.push(res.status);
        }
      }
      if (promises.length < selFeatures) {
        ServerError();
      } else {
        Created_app_Feature();
        setShowAssignFeatureModal(false);
        getOrgCv();
      }
    }
  };
  const getOrgCv = () => {
    transport(`org/org_data?org_uuid=${locState.uuidOrg}`)
      .then((res) => {
        if (res.data.status == "key and value not found") {
          setOrgCVs(undefined);
        } else {
          const featuresIndex = res.data.status.findIndex(
            (val) => val["features"]
          );
          const currObj = res.data.status.find((val) => val["curr"]);
          if (currObj) {
            setCurr(currObj["curr"]);
            setCurrency(currObj["curr"]);
          }
          setOrgFeatures(res.data.status[featuresIndex]["features"]);
          setOrgCVs(res.data);
        }
        // console.log('orgCv ===',data)
      })
      .catch((err) => {
        console.log("error in get org cv", err);
      });
  };

  const deleteOrgCv = () => {
    fetch(`https://api.trypadlock.com/org/org_data`, {
      method: "DELETE",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        org_uuid: locState.uuidOrg,
        name: deleteOrgCV_key,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == "org value and key got deleted") {
          getOrgCv();
          Delete_Org_cv();
          onCloseModel();
        } else {
          ServerError();
        }
      })
      .catch((err) => console.log("error in delete orgcv", err));
  };

  const editOrgCV = (e) => {
    e.preventDefault();
    fetch(`https://api.trypadlock.com/org/org_data`, {
      method: "POST",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        org_uuid: locState.uuidOrg,
        name: placeholderValue_orgCV_edit.key,
        main_key: orgValueName,
      }),
    })
      .then((res) => {
        res.json();
        Edit_Org_cv();
        setOrgValueName("");
        getOrgCv();
        onCloseModel();
      })
      .catch((err) => {
        onCloseModel();
      });
  };

  const editOrgCV_Modal = (app_info) => {
    setPlaceholderValue_orgCV_edit(app_info);
    setEditOrgCvModal(true);
    setCreateOrgCvModal(true);
  };

  const deleteOrgCV_Modal = (app_info) => {
    setDeleteOrgCV_key(app_info);
    setDeleteOrgCvModal(true);
  };

  const displayOrgsUser =
    orgsUserList == undefined ? (
      <h3 className='text-lg m-10'>No Users Found</h3>
    ) : (
      orgsUserList
        .slice(pagesOrgUserVisited, pagesOrgUserVisited + usersperPage)
        .map((orgsUser, orgUserIdx) => {
          return (
            <tr
              key={orgUserIdx}
              className={orgUserIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
              <td
                className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer hover:underline'
                onClick={(e) => {
                  handleNavigation(
                    e,
                    orgsUser.username,
                    orgsUser.user_id,
                    "userPage"
                  );
                }}>
                {orgsUser.username}
              </td>
              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                {orgsUser.email}
              </td>
              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                {orgsUser.first_name}
              </td>
              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                {orgsUser.last_name}
              </td>
              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                {orgsUser.last_login == null
                  ? ""
                  : orgsUser.last_login.slice(0, 10)}
              </td>
              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                {orgsUser.created == null ? "" : orgsUser.created.slice(0, 10)}
              </td>
            </tr>
          );
        })
    );

  const displayOrgFeatures =
    !orgFeatures || orgFeatures?.length === 0
      ? ""
      : orgFeatures.map((d, idx) => (
          <tr
            key={"custom_org_var_" + idx}
            className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
              {idx + 1}
            </td>
            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
              {d["name"]}
            </td>
            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
              {d["description"]}
            </td>
            {editPrice == null || editPrice?.id !== d["id"] ? (
              <td className='flex gap-4 p-2 justify-between items-center'>
                <span>
                  {curr == "inr" ? "₹" : "$"}
                  {d["price"]}
                </span>
                <PencilIcon
                  className='w-5 h-5 cursor-pointer'
                  onClick={() => {
                    setEditPrice(d);
                    setNewPrice(d["price"]);
                  }}
                />
              </td>
            ) : (
              <td className='flex p-2 gap-4 justify-between items-center'>
                <span className='flex'>
                  {curr == "inr" ? "₹" : "$"}
                  <input
                    type='number'
                    className='px-2 py-1 border-t-0 border-r-0 border-l-0 outline-none max-w-[6rem]'
                    value={newPrice}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        setNewPrice(event.target.value);
                      }
                    }}
                  />
                </span>
                <Check
                  className='w-5 h-5 cursor-pointer'
                  onClick={() => {
                    transport("users/edit_feature_price", {
                      method: "PUT",
                      data: {
                        org_uuid: locState.uuidOrg,
                        feature_id: editPrice["id"],
                        price: newPrice,
                      },
                    }).then((res) => {
                      if (res.status === 200) {
                        toast.dark("Price updated");
                        getOrgCv();
                      } else {
                        ServerError();
                      }
                      setEditPrice(null);
                    });
                  }}
                />
              </td>
            )}
          </tr>
        ));
  const displayOrgCustomVariable =
    orgCVs == undefined || orgCVs == "" || orgCVs == null
      ? ""
      : orgCVs.status.map(
          (d, idx) =>
            !d["features"] && (
              <tr
                key={"custom_org_var_" + idx}
                className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                  {Object.keys(d)}
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                  {Object.values(d)}
                </td>
                <td>
                  <p className='flex items-center gap-4'>
                    <span
                      className='px-6 py-4 whitespace-nowrap text-gray-600 text-xs'
                      onClick={() => {
                        editOrgCV_Modal({
                          key: Object.keys(d).toString(),
                          value: Object.values(d).toString(),
                        });
                      }}>
                      <PencilIcon className='w-5 h-5 ' />
                    </span>
                    <span
                      className='px-6 py-4 whitespace-nowrap text-gray-600 text-xs'
                      onClick={() => {
                        deleteOrgCV_Modal(Object.keys(d).toString());
                      }}>
                      <TrashIcon className='w-5 h-5 text-red-500' />
                    </span>
                  </p>
                </td>
              </tr>
            )
        );

  const userLoginChart = () => {
    fetch(
      `https://api.trypadlock.com/graph/org_graph?org_uuid=${locState.uuidOrg}`,
      {
        method: "GET",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setUserLoginChartData(data.data);
      });
  };

  const userLoginChartDate =
    userLoginChartData && userLoginChartData.map((el) => el.date);
  const userLOginChartUsers =
    userLoginChartData && userLoginChartData.map((el) => el.number_of_users);

  const userLoginChartOptions = {
    chart: {
      type: "column",
      height: 300,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}</b>",
    },
    title: {
      text: "",
    },
    plotOptions: {},
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      title: {
        text: "",
      },
      crosshair: true,
      categories: userLoginChartDate,
    },
    series: [
      {
        name: "Employees",
        color: "#000",
        data: userLOginChartUsers && userLOginChartUsers,
      },
    ],
  };

  const getDeactiviatedUsers = (org_uuid) => {
    fetch(`https://api.trypadlock.com/last/deactivated?org_uuid=${org_uuid}`, {
      method: "GET",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status == 401) {
          refreshTokenn();
        }
      })
      .then((data) => {
        setDeactivatedUsers(data.data);
      });
  };
  const pagesdeactivatedOrgUserVisited =
    deactivatedUserPageNumber * usersperPage;
  const pageDeactivatedUserOrgCount = Math.ceil(
    deactivatedUsers == undefined ? "" : deactivatedUsers.length / usersperPage
  );
  const changeDeactivatedOrgUserPage = ({ selected }) => {
    setDeactivatedUserPageNumber(selected);
  };

  const getInactiveUsers = (org_uuid) => {
    fetch(`https://api.trypadlock.com/last/inactive?org_uuid=${org_uuid}`, {
      method: "GET",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status == 401) {
          refreshTokenn();
        }
      })
      .then((data) => {
        setInactiveUsers(data.data);
      });
  };
  const pagesInactiveOrgUserVisited = inactiveUsersPageNumber * usersperPage;
  const pageInactiveUserOrgCount = Math.ceil(
    inactiveUsers == undefined ? "" : inactiveUsers.length / usersperPage
  );
  const changeInactiveOrgUserPage = ({ selected }) => {
    setInactiveUsersPageNumber(selected);
  };

  const getWebLoggedUsers = (org_uuid) => {
    fetch(`https://api.trypadlock.com/last/weblogged?org_uuid=${org_uuid}`, {
      method: "GET",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status == 401) {
          refreshTokenn();
        }
      })
      .then((data) => {
        setWebLoggedUsers(data.data);
      });
  };
  const pagesWebLoggedOrgUserVisited = webLoggedPageNumber * usersperPage;
  const pageWebLoggedUserOrgCount = Math.ceil(
    webLoggedUsers == undefined ? "" : webLoggedUsers.length / usersperPage
  );
  const changeWebLoggedOrgUserPage = ({ selected }) => {
    setWebLoggedPageNumber(selected);
  };

  const getMobileLoggedusers = (org_uuid) => {
    fetch(`https://api.trypadlock.com/last/mobilelogged?org_uuid=${org_uuid}`, {
      method: "GET",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status == 401) {
          refreshTokenn();
        }
      })
      .then((data) => {
        setMobileLoggedUsers(data.data);
      });
  };
  const pagesMobileLoggedOrgUserVisited = mobileLoggedPageNumber * usersperPage;
  const pageMobileLoggedUserOrgCount = Math.ceil(
    mobileLoggedUsers == undefined
      ? ""
      : mobileLoggedUsers.length / usersperPage
  );
  const changeMobileLoggedOrgUserPage = ({ selected }) => {
    setMobileLoggedPageNumber(selected);
  };

  const getDesktopLoggedUsers = (org_uuid) => {
    fetch(
      `https://api.trypadlock.com/last/desktoplogged?org_uuid=${org_uuid}&date=${moment(
        new Date()
      ).format("YYYY-MM-DD")}`,
      {
        method: "GET",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status == 401) {
          refreshTokenn();
        }
      })
      .then((data) => {
        setDesktopLoggedUsers(data.data);
      });
  };
  const pagesDesktopLoggedOrgUserVisited =
    desktopLoggesPageNumber * usersperPage;
  const pageDesktopLoggedUserOrgCount = Math.ceil(
    desktopLoggedUsers == undefined
      ? ""
      : desktopLoggedUsers.length / usersperPage
  );
  const changeDesktopLoggedOrgUserPage = ({ selected }) => {
    setDesktopLoggedPageNumber(selected);
  };

  const reactivateUser = (user_uuid) => {
    fetch(
      `https://api.trypadlock.com/last/useractivate?user_uuid=${user_uuid}`,
      {
        method: "PUT",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
        },
      }
    ).then((res) => {
      if (res.status == 200) {
        toast("User Activated Successfully");
        getDeactiviatedUsers(locState.uuidOrg);
      } else if (res.status == 401) {
        refreshTokenn();
      }
    });
  };

  const deactivateUser = (user_uuid) => {
    fetch(
      `https://api.trypadlock.com/last/userdeactivate?user_uuid=${user_uuid}`,
      {
        method: "PUT",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
        },
      }
    ).then((res) => {
      if (res.status == 200) {
        toast("User Deactivated Successfully");
        getInactiveUsers(locState.uuidOrg);
        getDeactiviatedUsers(locState.uuidOrg);
        getUserlistOrg();
      } else if (res.status == 401) {
        refreshTokenn();
      }
    });
  };

  const hanldeDeleteOrg = () => {
    deleteORG(locState.uuidOrg).then((res) => {
      console.log("deleteEORG", res);
      if (res?.status == 200) {
        toast.success("Organization deactivated successfully");
        history.goBack();
      }
    });
  };

  const [isSecretKeyGenerated, setSecretKeyGenerated] = useState(false);

  const getSecretKey = () => {
    const appId = locState?.appId;
    const org_uuid = locState?.uuidOrg;
    fetch(
      `https://api.trypadlock.com/loginapp/get_secret_key/${appId}/${org_uuid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          return response.json();
        } else {
          setSecretKeyGenerated(false);
        }
      })
      .then((data) => {
        setSecretKeyGenerated(data.secretKey);
      })
      .catch((error) => {
        console.log("Failed to get secret key");
        setSecretKeyGenerated(false);
      });
  };
  useEffect(() => {
    getSecretKey();
  }, []);

  const generateSecretKey = () => {
    const appId = locState?.appId;
    const org_uuid = locState?.uuidOrg;
    const appName = locState?.appName;
    const data = {
      appName: appName,
      appId: appId,
      org_uuid: org_uuid,
    };
    fetch(`https://api.trypadlock.com/loginapp/generate_secret_key`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Jwt " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else {
        }
      })
      .then((data) => {
        toast.success("Secret key generated successfully");
        setSecretKeyGenerated(true);
      });
  };

  const updateSecretKey = () => {
    const appId = locState?.appId;
    const org_uuid = locState?.uuidOrg;
    fetch(
      `https://api.trypadlock.com/loginapp/update_secret_key/${appId}/${org_uuid}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          authorization: "Jwt " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status == 401) {
        }
      })
      .then((data) => {
        toast.success("Secret key updated successfully");
      })
      .catch((err) => {
        toast.error("Failed to update secret key");
      });
  };

  return (
    <>
      <ProfileNav />

      <div className='p-20 smt:p-2'>
        <p
          className='flex left-0 top-2 bg-gray-700 rounded-md w-16 h-8 text-white font-bold justify-center items-center shadow-lg cursor-pointer'
          onClick={() => {
            history.goBack();
          }}>
          back
        </p>
        <div className=''>
          <div className='flex justify-center items-center'>
            <div className='flex justify-center items-center mb-4 w-24 h-24 rounded-full border object-fill'>
              {orgpicture.url == "None" ? (
                <CorporateFareIcon style={{ fontSize: "34px" }} />
              ) : orgpicture == "" ? (
                <p className='loader'></p>
              ) : (
                <img
                  src={orgpicture.url}
                  alt='Organization'
                  className='flex items-center rounded-full w-full h-full justify-center object-cover p-1'
                />
              )}
            </div>
          </div>
          <div className='flex justify-center items-center mb-2'>
            <h3 className='text-4xl underline capitalize font-bold text-center flex justify-center'>
              {locState.orgName}
            </h3>
          </div>
          <div className='flex justify-center items-center my-2'>
            <p
              className='px-2 p-1 mt-3 flex justify-center items-center text-sm rounded-md bg-gray-800 hover:bg-gray-900 text-white cursor-pointer'
              onClick={() => {
                setIsOpenModal(true);
              }}>
              Edit Organization
            </p>
            <button
              title='Extend trial'
              className='px-2 p-1 ml-3 mt-3 flex justify-center items-center text-sm rounded-md bg-gray-800 hover:bg-gray-900 text-white cursor-pointer'
              onClick={() => {
                setShowTrialPeriodModal(true);
              }}>
              {trailDays?.free ? (
                <span>Free Trial </span>
              ) : (
                <span>
                  Trial Expired on{" "}
                  {moment(trailDays?.expireDate).format("MMM Do YYYY")}
                </span>
              )}
            </button>
            <button
              className='px-2 p-1 ml-3 mt-3 flex justify-center items-center text-sm rounded-md bg-gray-800 hover:bg-gray-900 text-white cursor-pointer'
              onClick={() => {
                hanldeDeleteOrg();
              }}>{`Deactivate`}</button>
          </div>

          <div className='mb-5'>
            <dl className='mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-2'>
              {stats.map((item) => (
                <div
                  key={item.id}
                  className='relative bg-white border border-gray-100 pt-5 px-4 pb-1 sm:pt-6 sm:px-6 shadow overflow-hidden'>
                  <dt>
                    <p className='text-sm font-medium text-gray-500 truncate'>
                      {item.name}
                    </p>
                  </dt>
                  <dd className='pb-6 flex justify-between items-baseline sm:pb-7'>
                    <span className='text-2xl font-semibold text-gray-900'>
                      {item.stat}
                    </span>
                    {/* <p className={classNames( item.changeType === "increase" ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600", "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" )} >
                        {item.changeType === "increase" ? (
                          <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                        ) : (
                          <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                        )}
                        <span className="sr-only"> {item.changeType === "increase" ? "Increased" : "Decreased"}{" "} by </span>
                        {item.change}
                      </p> */}
                  </dd>
                </div>
              ))}
            </dl>
            <div className='mt-10'>
              <div className='text-base'>
                <b>Creator email: </b>
                {ownerDetails.users?.email ?? "None"}
              </div>
              <div className='text-base'>
                <b>Creator username: </b>
                {ownerDetails.users?.username ?? "None"}
              </div>
              <div className='text-base'>
                <b>Created on: </b>
                {ownerDetails.org_created_date
                  ? ownerDetails.org_created_date.slice(0, 10)
                  : "None"}
              </div>
            </div>
          </div>

          <div className='flex flex-col'>
            <p className='text-lg leading-6 font-medium text-gray-900 mr-4'>
              Secet Key
            </p>
            <div className='my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 '>
              {isSecretKeyGenerated ? (
                <div className='flex justify-center items-center gap-5'>
                  <p>Secretkey already generated</p>

                  <div
                    className='p-2 rounded-md bg-gray-800 hover:bg-gray-900 text-white cursor-pointer'
                    onClick={updateSecretKey}>
                    Update Secretkey
                  </div>
                </div>
              ) : (
                <div className='flex justify-center items-center gap-5'>
                  <p>Generate new Secretkey</p>
                  <div
                    className='p-2 rounded-md bg-gray-800 hover:bg-gray-900 text-white cursor-pointer'
                    onClick={generateSecretKey}>
                    Generate Secretkey
                  </div>
                </div>
              )}
            </div>
          </div>

          {userLOginChartUsers?.length > 0 && (
            <div>
              <div className='mt-4'>
                <ReactHighcharts config={userLoginChartOptions}>
                  {" "}
                </ReactHighcharts>{" "}
              </div>
            </div>
          )}

          <div className='flex justify-end items-center mt-5'>
            <p
              className='p-2 rounded-md bg-gray-800 hover:bg-gray-900 text-white cursor-pointer'
              onClick={() => {
                setIsOpenModal(true);
                setEditUserModel(true);
              }}>
              Add User
            </p>
            <p
              className='ml-4 p-2 rounded-md bg-gray-800 hover:bg-gray-900 text-white cursor-pointer'
              onClick={() => setIsPlanModal(true)}>
              Set Plan
            </p>
            <div className='flex '>
              <>
                <div className=' inset-y-0 border p-2 z-10 rounded-l-md flex items-center pointer-events-none'>
                  <SearchIcon
                    className='h-5 w-5 z-10 text-gray-400'
                    aria-hidden='true'
                  />
                </div>
                <input
                  type='text'
                  name='desktop-search-candidate'
                  id='desktop-search-candidate'
                  className=' w-full rounded-r-md  sm:block sm:text-sm border-gray-300'
                  placeholder='Search Users'
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </>
            </div>
          </div>
        </div>

        {/* USERS IN ORG */}
        <div className='flex flex-col'>
          <div className='my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <td
                        className='flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={nameArrowSort}>
                        <span>username</span>
                        <span onClick={nameSortOpen}>
                          {isNameOpen === true ? (
                            <ArrowSmDownIcon className='w-5 h-5 hover:cursor-pointer' />
                          ) : (
                            <ArrowSmUpIcon className='w-5 h-5 hover:cursor-pointer' />
                          )}
                        </span>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        email
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        first name
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        last name
                      </td>
                      <td
                        className=' px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={loggedArrowSort}>
                        <p className='flex gap-2 items-center'>
                          <span>Last Logged In</span>
                          <span onClick={loggedSortOpen}>
                            {isLastLogged ? (
                              <ArrowSmDownIcon className='w-5 h-5 hover:cursor-pointer' />
                            ) : (
                              <ArrowSmUpIcon className='w-5 h-5 hover:cursor-pointer' />
                            )}
                          </span>
                        </p>
                      </td>
                      <td
                        className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={dateArrowSort}>
                        <p className='flex items-center gap-2'>
                          <span>Created</span>
                          <span onClick={dateSortOpen}>
                            {isDateOpen ? (
                              <ArrowSmDownIcon className='w-5 h-5 hover:cursor-pointer' />
                            ) : (
                              <ArrowSmUpIcon className='w-5 h-5 hover:cursor-pointer' />
                            )}
                          </span>
                        </p>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        Action
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {orgsUserList == undefined ? (
                      <tr>
                        <td colSpan={6} className='text-lg m-10'>
                          No Users Found
                        </td>
                      </tr>
                    ) : (
                      orgsUserList
                        .filter((orgsUser) =>
                          orgsUser.username
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .slice(
                          pagesOrgUserVisited,
                          pagesOrgUserVisited + usersperPage
                        )
                        .map((orgsUser, orgUserIdx) => {
                          // console.log('orgurse', orgsUser)
                          return (
                            <tr
                              key={orgUserIdx}
                              className={
                                orgUserIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }>
                              <td
                                className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer hover:underline'
                                onClick={(e) => {
                                  handleNavigation(
                                    e,
                                    orgsUser.username,
                                    orgsUser.user_id,
                                    "userPage"
                                  );
                                }}>
                                {orgsUser.username}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.email}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.first_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_login == null
                                  ? ""
                                  : orgsUser.last_login.slice(0, 10)}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.created == null
                                  ? ""
                                  : orgsUser.created.slice(0, 10)}
                              </td>
                              <td
                                className='px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer hover:underline'
                                onClick={() => {
                                  deactivateUser(orgsUser.user_id);
                                }}>
                                Deactivate
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* {GTable(displayOrgsUser,['username','email','first name','last name', 'Last Logged In', "Created"])} */}
        <NPagination
          pageCount={pageUserOrgCount}
          onPageChange={changeOrgUserPage}
        />
        <hr className='my-10' />

        {/* DEACTIVATED USERS */}
        <div className='flex flex-col'>
          <p className='text-lg leading-6 font-medium text-gray-900 mr-4'>
            Deactivated Users
          </p>
          <div className='my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <td
                        className='flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={nameArrowSort}>
                        <p>username</p>
                        {/* <span onClick={nameSortOpen}>{isNameOpen === true ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                          ): (
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                          )
                          }</span> */}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        email
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        first name
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        last name
                      </td>
                      <td
                        className=' px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={loggedArrowSort}>
                        <p>Last Logged In</p>
                        {/* <span onClick={loggedSortOpen}>{isLastLogged ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer"  />
                          ):(
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer"  />
                          )}</span> */}
                      </td>
                      <td
                        className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={dateArrowSort}>
                        <p>Created</p>
                        {/* <span onClick={dateSortOpen}>{isDateOpen ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                          ) : (
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                          )}</span> */}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        Action
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {deactivatedUsers == undefined ? (
                      <h3 className='text-lg m-10'>No Users Found </h3>
                    ) : (
                      deactivatedUsers
                        .slice(
                          pagesdeactivatedOrgUserVisited,
                          pagesdeactivatedOrgUserVisited + usersperPage
                        )
                        .map((orgsUser, orgUserIdx) => {
                          // console.log('orgurse', orgsUser)
                          return (
                            <tr
                              key={orgUserIdx}
                              className={
                                orgUserIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }>
                              <td
                                className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer hover:underline'
                                onClick={(e) => {
                                  handleNavigation(
                                    e,
                                    orgsUser.username,
                                    orgsUser.user_id,
                                    "userPage"
                                  );
                                }}>
                                {orgsUser.username}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.email}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.first_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_login == null
                                  ? ""
                                  : orgsUser.last_login.slice(0, 10)}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.created == null
                                  ? ""
                                  : orgsUser.created.slice(0, 10)}
                              </td>
                              <td
                                className='px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer hover:underline'
                                onClick={() => {
                                  reactivateUser(orgsUser.user_id);
                                }}>
                                Activate
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* {GTable(displayOrgsUser,['username','email','first name','last name', 'Last Logged In', "Created"])} */}
        <NPagination
          pageCount={pageDeactivatedUserOrgCount}
          onPageChange={changeDeactivatedOrgUserPage}
        />
        <hr className='my-10' />

        {/* INACTIVE USERS */}
        <div className='flex flex-col'>
          <p className='text-lg leading-6 font-medium text-gray-900 mr-4'>
            Inactive Users
          </p>
          <div className='my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <td
                        className='flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={nameArrowSort}>
                        <p>username</p>
                        {/* <span onClick={nameSortOpen}>{isNameOpen === true ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                          ): (
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                          )
                          }</span> */}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        email
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        first name
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        last name
                      </td>
                      <td
                        className=' px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={loggedArrowSort}>
                        <p>Last Logged In</p>
                        {/* <span onClick={loggedSortOpen}>{isLastLogged ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer"  />
                          ):(
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer"  />
                          )}</span> */}
                      </td>
                      <td
                        className='flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={dateArrowSort}>
                        <p>Created</p>
                        {/* <span onClick={dateSortOpen}>{isDateOpen ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                          ) : (
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                          )}</span> */}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {inactiveUsers == undefined ? (
                      <h3 className='text-lg m-10'>No Users Found </h3>
                    ) : (
                      inactiveUsers
                        .slice(
                          pagesInactiveOrgUserVisited,
                          pagesInactiveOrgUserVisited + usersperPage
                        )
                        .map((orgsUser, orgUserIdx) => {
                          // console.log('orgurse', orgsUser)
                          return (
                            <tr
                              key={orgUserIdx}
                              className={
                                orgUserIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }>
                              <td
                                className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer hover:underline'
                                onClick={(e) => {
                                  handleNavigation(
                                    e,
                                    orgsUser.username,
                                    orgsUser.user_id,
                                    "userPage"
                                  );
                                }}>
                                {orgsUser.username}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.email}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.first_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_login == null
                                  ? ""
                                  : orgsUser.last_login.slice(0, 10)}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.created == null
                                  ? ""
                                  : orgsUser.created.slice(0, 10)}
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NPagination
          pageCount={pageInactiveUserOrgCount}
          onPageChange={changeInactiveOrgUserPage}
        />
        <hr className='my-10' />

        {/* WEB LOGGED USERS */}
        <div className='flex flex-col'>
          <p className='text-lg leading-6 font-medium text-gray-900 mr-4'>
            Web Logged Users
          </p>
          <div className='my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <td
                        className='flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={nameArrowSort}>
                        <p>username</p>
                        {/* <span onClick={nameSortOpen}>{isNameOpen === true ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                          ): (
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                          )
                          }</span> */}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        email
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        first name
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        last name
                      </td>
                      <td
                        className=' px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={loggedArrowSort}>
                        <p>Last Logged In</p>
                        {/* <span onClick={loggedSortOpen}>{isLastLogged ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer"  />
                          ):(
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer"  />
                          )}</span> */}
                      </td>
                      <td
                        className='flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={dateArrowSort}>
                        <p>Created</p>
                        {/* <span onClick={dateSortOpen}>{isDateOpen ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                          ) : (
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                          )}</span> */}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {webLoggedUsers == undefined ? (
                      <h3 className='text-lg m-10'>No Users Found </h3>
                    ) : (
                      webLoggedUsers
                        .slice(
                          pagesWebLoggedOrgUserVisited,
                          pagesWebLoggedOrgUserVisited + usersperPage
                        )
                        .map((orgsUser, orgUserIdx) => {
                          // console.log('orgurse', orgsUser)
                          return (
                            <tr
                              key={orgUserIdx}
                              className={
                                orgUserIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }>
                              <td
                                className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer hover:underline'
                                onClick={(e) => {
                                  handleNavigation(
                                    e,
                                    orgsUser.username,
                                    orgsUser.user_id,
                                    "userPage"
                                  );
                                }}>
                                {orgsUser.username}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.email}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.first_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_login == null
                                  ? ""
                                  : orgsUser.last_login.slice(0, 10)}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.created == null
                                  ? ""
                                  : orgsUser.created.slice(0, 10)}
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NPagination
          pageCount={pageWebLoggedUserOrgCount}
          onPageChange={changeWebLoggedOrgUserPage}
        />
        <hr className='my-10' />

        {/* MOBILE LOGGED USERS */}
        <div className='flex flex-col'>
          <p className='text-lg leading-6 font-medium text-gray-900 mr-4'>
            Desktop Logged Users
          </p>
          <div className='my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <td
                        className='flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={nameArrowSort}>
                        <p>username</p>
                        {/* <span onClick={nameSortOpen}>{isNameOpen === true ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                          ): (
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                          )
                          }</span> */}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        email
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        first name
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        last name
                      </td>
                      <td
                        className=' px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={loggedArrowSort}>
                        <p>Last Logged In</p>
                        {/* <span onClick={loggedSortOpen}>{isLastLogged ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer"  />
                          ):(
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer"  />
                          )}</span> */}
                      </td>
                      <td
                        className='flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                        onClick={dateArrowSort}>
                        <p>Created</p>
                        {/* <span onClick={dateSortOpen}>{isDateOpen ? (
                            <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                          ) : (
                            <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                          )}</span> */}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {desktopLoggedUsers == undefined ? (
                      <h3 className='text-lg m-10'>No Users Found </h3>
                    ) : (
                      desktopLoggedUsers
                        .slice(
                          pagesDesktopLoggedOrgUserVisited,
                          pagesDesktopLoggedOrgUserVisited + usersperPage
                        )
                        .map((orgsUser, orgUserIdx) => {
                          // console.log('orgurse', orgsUser)
                          return (
                            <tr
                              key={orgUserIdx}
                              className={
                                orgUserIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }>
                              <td
                                className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer hover:underline'
                                onClick={(e) => {
                                  handleNavigation(
                                    e,
                                    orgsUser.username,
                                    orgsUser.user_id,
                                    "userPage"
                                  );
                                }}>
                                {orgsUser.username}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.email}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.first_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_name}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.last_login == null
                                  ? ""
                                  : orgsUser.last_login.slice(0, 10)}
                              </td>
                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                {orgsUser.created == null
                                  ? ""
                                  : orgsUser.created.slice(0, 10)}
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NPagination
          pageCount={pageDesktopLoggedUserOrgCount}
          onPageChange={changeDesktopLoggedOrgUserPage}
        />
        <hr className='my-10' />

        {/* for custom variables for ORG */}
        <div className='my-14'>
          <div className='flex'>
            <h3 className='text-lg leading-6 font-medium text-gray-900 mr-4'>
              Features
            </h3>
            <p
              className='w-9 h-6 rounded-full bg-gray-800 text-white flex justify-center items-center cursor-pointer font-bold'
              onClick={() => {
                setShowAssignFeatureModal(true);
              }}>
              +
            </p>
          </div>
          <div>
            {GTable(displayOrgFeatures, [
              "S.no",
              "Name",
              "Description",
              "Price",
            ])}
          </div>
        </div>
        <div className='my-14'>
          <div className='flex'>
            <h3 className='text-lg leading-6 font-medium text-gray-900 mr-4'>
              Custom Variables
            </h3>
            <p
              className='w-9 h-6 rounded-full bg-gray-800 text-white flex justify-center items-center cursor-pointer font-bold'
              onClick={() => {
                setCreateOrgCvModal(true);
              }}>
              +
            </p>
          </div>
          <div>
            {GTable(displayOrgCustomVariable, ["Key", "Value", "Action"])}
          </div>
        </div>

        {/* UNCOMMENT BELOW CODE FOR TEAMs */}
        {/* <hr className='mt-14'/>
          <OrgTeams org_uuid={locState.uuidOrg}/> */}
        <div className='flex gap-8 mb-5 flex-wrap'>
          <div className='mt-10'>
            <p className='text-2xl my-4'>Payments</p>
            <button
              onClick={(e) => handleNavigation(e, "", "", "payments")}
              className='bg-gray-800 text-white p-1 px-4 rounded-xl font-semibold shadow-md'>
              Payment History
            </button>
          </div>

          <div className='mt-10'>
            <p className='text-2xl my-4'>Activity Log</p>
            <button
              onClick={(e) => {
                handleNavigation(e, "", "", "orgLog");
              }}
              className='bg-gray-800 text-white p-1 px-4 rounded-xl font-semibold shadow-md'>
              Logs
            </button>
          </div>
        </div>

        <Modal
          open={isOpenModel}
          onClose={onCloseModel}
          classNames={{ overlay: "customOverlay", modal: "customModal" }}>
          {editUserModel ? (
            <>
              <div className='grid grid-cols-2 content-center'>
                <label className='text-lg font-bold mt-7'> User Name</label>
                <input
                  type='text'
                  onChange={(e) => {
                    setAddUserNameOrg(e.target.value);
                  }}
                  placeholder='UserName'
                  className='rounded-md mt-5'
                />
                <label className='text-lg font-bold mt-7'> First Name</label>
                <input
                  type='text'
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  placeholder='First Name'
                  className='rounded-md mt-5'
                />
                <label className='text-lg font-bold mt-7'> Last Name</label>
                <input
                  type='text'
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  placeholder='Last Name'
                  className='rounded-md mt-5'
                />
                <label className='text-lg font-bold mt-7'>Email</label>
                <input
                  type='text'
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder='Email'
                  className='rounded-md mt-5'
                />
                <label className='text-lg font-bold mt-7'>Password</label>
                <input
                  type='text'
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder='Password'
                  className='rounded-md mt-5'
                />
                <label className='text-lg font-bold mt-7'>Timezone</label>
                <TimezoneSelect
                  value={timezone}
                  onChange={setTimeZone}
                  className='rounded-md mt-5'
                />
                <label className='text-lg font-bold mt-7'>Role</label>
                <Dropdown
                  options={rolesOptions}
                  onChange={(e) => {
                    handleRoleSelect(e);
                  }}
                  className='rounded-md mt-5'
                  controlClassName='myControlClassName'
                  placeholder='Role'
                />
              </div>
              <div className='flex justify-center items-center'>
                <button
                  className='bg-gray-800 text-white rounded-md w-28 p-2.5 mt-8 font-semibold flex justify-center items-center'
                  onClick={(e) => {
                    addUserOrg(e);
                  }}>
                  Submit
                </button>
              </div>
            </>
          ) : (
            <div className='flex flex-col'>
              {/* <label className="text-lg font-bold mt-4"> Edit Organization Name</label>
                  <input type='text' onChange={(e)=>{setEditOrgName(e.target.value)}} placeholder="Organization Name" className="mt-2 rounded-md mb-2"/>
                  <label className="text-lg font-bold mt-4"> Edit Fixed Value</label>
                  <input type='text' onChange={(e)=>{setfixedValueOrg(e.target.value)}} placeholder="True or False" className="mb-4 rounded-md"/> */}

              <label className='text-lg font-bold mt-4 mb-2'>
                Organization Picture
              </label>
              <input
                type='file'
                onChange={(e) => {
                  setOrgPic(e.target.files[0]);
                }}
                className='mb-6 rounded-md'
                ref={fileInputRef}
              />
              <button
                className='bg-gray-800 text-white rounded-md p-2.5 font-semibold'
                onClick={(e) => {
                  uploadPicOrganization(e);
                }}>
                Submit
              </button>
            </div>
          )}
        </Modal>
        <SetPlanModal
          open={isPlanModal}
          onCloseModal={onCloseModel}
          onOpenModal={onOpenModal}
        />

        {/* <Modal open={isPlanModal} onClose={onCloseModel} classNames={{overlay: 'customOverlayy', modal: 'customModall'}}>
            <SimpleBarReact style={{ height: '100vh' }}>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-7 mt-4 px-4"> 
                {priceData.map((plan,idx)=>(
                  <PlanCard
                    key={idx}
                    plan_name={plan.name}
                    planCurrency={plan.currency}
                    planId={plan.id}
                    planDescription={plan.info}
                    appId={locState.appId}
                    price={plan.price}
                    org_uuid={locState.uuidOrg}
                    setIsPlanModal={setIsPlanModal}
                  />
                ))}
            </div>
            </SimpleBarReact>
          </Modal> */}
        <Modal
          open={showAssignFeatureModal}
          onClose={onCloseModel}
          classNames={{ overlay: "customOverlay", modal: "customModal" }}>
          <div>
            <label htmlFor='features' className='font-semibold'>
              Add Features
            </label>
            {(!curr || curr.length === 0) && (
              <div className='flex w-full gap-4 items-center p-1 justify-between'>
                <span className='font-semibold'>Curency: </span>
                <select
                  label='Currency'
                  value={currency}
                  className='flex-1 pl-0.5 !py-0.5'
                  onChange={(ev) => {
                    setCurrency(ev.target.value);
                  }}>
                  <option key={"inr"} value={"inr"}>
                    INR
                  </option>
                  <option key={"usd"} value={"usd"}>
                    USD
                  </option>
                </select>
              </div>
            )}
            <div id='features' className='max-h-[40vh] overflow-y-auto'>
              {features
                ?.filter((fVal) => {
                  for (let i = 0; i < orgFeatures.length; i++) {
                    if (fVal.id === orgFeatures[i].id) {
                      return false;
                    }
                  }
                  return true;
                })
                ?.map((feature) => (
                  <div
                    key={"feature_select_" + feature.id}
                    className='w-full flex justify-between items-end gap-5'>
                    <div>
                      <Checkbox
                        id={"feature_" + feature.id}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelFeatures([...selFeatures, feature]);
                          } else {
                            setSelFeatures(
                              selFeatures.filter((val) => val.id !== feature.id)
                            );
                          }
                        }}
                        color='default'
                        checked={selFeatures.includes(feature)}
                      />
                      <label htmlFor={"feature_" + feature.id}>
                        {feature.name}
                      </label>
                    </div>
                    <div className='py-1'>
                      <label
                        className='pr-1'
                        htmlFor={"feature_price_" + feature.id}>
                        Price:
                      </label>
                      <input
                        type='number'
                        className='max-w-[100px] py-1 rounded'
                        id={"feature_price_" + feature.id}
                        disabled={
                          selFeatures.findIndex(
                            (val) => val.id === feature.id
                          ) < 0
                        }
                        onChange={(e) => {
                          const updatedFeatures = [...selFeatures];
                          for (let i = 0; i < updatedFeatures.length; i++) {
                            if (updatedFeatures[i].id === feature.id) {
                              updatedFeatures[i]["feature_price"] =
                                e.target.value;
                              setSelFeatures(updatedFeatures);
                              break;
                            }
                          }
                        }}
                        value={feature["feature_price"] ?? 0}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <button
              className='bg-gray-900 text-white rounded-md p-2.5 font-semibold'
              onClick={(e) => {
                addOrgFeatures(e);
              }}>
              Add
            </button>
          </div>
        </Modal>
        <Modal
          open={createorgCvModal}
          onClose={onCloseModel}
          classNames={{ overlay: "customOverlay", modal: "customModal" }}>
          {editOrgCvModal ? (
            <div>
              <p className='text-2xl my-5 font-bold'>
                Edit Value for Custom Variable
              </p>
              <div className='flex flex-col'>
                <label className='text-md font-bold'>Key Name</label>
                <input
                  type='text'
                  disabled
                  name='appKey'
                  placeholder='Key'
                  value={placeholderValue_orgCV_edit.key}
                  className='mt-2 mb-4 rounded-md'
                />
                <label className='text-md font-bold'>Value</label>
                <input
                  type='text'
                  placeholder='Value'
                  onChange={(e) => {
                    setOrgValueName(e.target.value);
                  }}
                  className='mt-2 mb-4 rounded-md'
                />
                <button
                  className='bg-gray-900 text-white rounded-md p-2.5 font-semibold'
                  onClick={(e) => {
                    editOrgCV(e);
                  }}>
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p className='text-2xl my-5 font-bold'>Create Custom Variable</p>
              <div className='flex flex-col'>
                <label className='text-md font-bold'>Key Name</label>
                <input
                  type='text'
                  onChange={(e) => {
                    setOrgKeyName(e.target.value);
                  }}
                  placeholder='Enter Key Name'
                  className='mt-2 mb-4 rounded-md'
                />
                <label className='text-md font-bold'>Value</label>
                <input
                  type='text'
                  onChange={(e) => {
                    setOrgValueName(e.target.value);
                  }}
                  placeholder='Enter Value'
                  className='mt-2 mb-4 rounded-md'
                />
                <button
                  className='bg-gray-900 text-white rounded-md p-2.5 font-semibold'
                  onClick={(e) => {
                    addOrgCv(e);
                  }}>
                  Submit
                </button>
              </div>
            </div>
          )}
        </Modal>
        <Modal
          open={deleteOrgCvModal}
          onClose={onCloseModel}
          classNames={{ overlay: "customOverlay", modal: "customModal" }}>
          <div>
            <label>Are you sure you want to Delete?</label>
            <div className='mt-8 flex justify-center items-center'>
              <button
                onClick={() => {
                  onCloseModel();
                  setDeleteOrgCV_key("");
                }}
                className='bg-gray-700 text-white font-bold rounded-md w-24 h-8 mr-4 '>
                Cancel
              </button>
              <button
                onClick={() => {
                  deleteOrgCv();
                }}
                className='bg-red-600 text-white font-bold rounded-md w-24 h-8'>
                Yes
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          open={showTrailPeriodModal}
          onClose={() => {
            setShowTrialPeriodModal(false);
          }}
          classNames={{ overlay: "customOverlayy", modal: "customModal" }}>
          <p className='border-b border-gray-400 font-semibold text-xl'>
            Extend Trial Period
          </p>
          <div className='mt-2 flex flex-col'>
            <div className='mt-2'>
              <p className='text-gray-400'>
                {moment(trailDays?.expireDate).diff(moment(new Date())) < 0
                  ? "Expired"
                  : "Expires"}{" "}
                on: {moment(trailDays?.expireDate).format("MMM Do YYYY")}
              </p>
              <input
                type='date'
                className='h-9 w-44 mt-3 rounded-sm'
                id='expDate'
                onChange={(date) => {
                  setTrialEndDate(date.target.value);
                }}
              />
            </div>
            <button
              className='bg-gray-800 h-10 text-white p-1 mt-5 rounded-md'
              onClick={() => {
                extendTrialPeriod();
              }}>
              Confirm
            </button>
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </>
  );
};

export default EachOrgPage;
